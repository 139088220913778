<template>
  <div>
    <div
      v-if="packagesfetchState.started"
      class="
        empty-package--content-area
        bg-white
        flex
        justify-center
        items-center
        w-6/12
        h-80
        rounded-lg
        ring-2-gray-500
      "
    >
    <Loader />
      Fetching packages...
    </div>

    <div v-if="packagesfetchState.has_data">
      <sub-header  :showBtn="false" />
      <div class="grid grid-cols-4 lg:grid-cols-3 wide:grid-cols-3 md:grid-cols-2  items-stretch justify-items-stretch gap-4 mt-6 mx-6">
        <div v-for="tenant_package in packages" :key="tenant_package.id">
          <packageCard
            class="h-full"
            :edit="true"
            :hideButtons="true"
            :tenant_package="tenant_package"
            :progress="true"
          />
        </div>
      </div>
    </div>

    <div v-if="packagesfetchState.no_data">
      <div
        class="
          empty-package--content-area
          bg-white
          flex
          justify-center
          items-center
          w-6/12
          h-80
          rounded-lg
          ring-2-gray-500
        "
      >
        <div class="flex flex-col justify-center items-center">
          <div>You do not have any active packages yet.</div>
         
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import packageCard from "@/components/package-card";
import axios from "@/axios";
import SubHeader from "@/components/SubHeader";
import Loader from "@/components/loader"
export default {
  name: "create-case",
  components: {
    packageCard,
    SubHeader,
    Loader
  },
  async mounted() {
    await this.fetchTenantPackages();
  },
  data() {
    return {
      packagesfetchState: {
        started: true,
        nodata: false,
        has_data: false,
      },

      packages: [
        //  {
        //     "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        //     "package_name": "BS648",
        //     "package_name_internal": "string",
        //     "package_description": "string",
        //     "turn_around_time": 0,
        //     "client_email_template": "string",
        //     "candidate_email_template": "string",
        //     "max_candidate_chase_email": 0,
        //     "min_candidate_chase_email": 0,
        //     "tenant_id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        //     "is_custom": true,
        //     "created_at": "2022-10-31T10:19:33.463Z",
        //     "modified_at": "2022-10-31T10:19:33.463Z",
        //     "created_by": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        //     "modified_by": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        //     "mark_as_default": true
        // }
        // {"name":"BS648", description:"A test package", sla:10, id:1},
        // {"name":"BS648", description:"A test package", sla:10, id:2},
        // {"name":"BS648", description:"A test package", sla:10, id:3},
        // {"name":"BS648", description:"A test package", sla:10, id:4},
        // {"name":"BS648", description:"A test package", sla:10, id:5},
        // {"name":"BS648", description:"A test package", sla:10, id:6},
        // {"name":"BS648", description:"A test package", sla:10, id:7},
        // {"name":"BS648", description:"A test package", sla:10, id:8},
        // {"name":"BS648", description:"A test package", sla:10, id:9},
      ],
    };
  },
  methods: {
    async fetchTenantPackages() {
      // this.isLoading = true

      const data = await axios.get(`/screening-package/client-packages`);
      this.packages = data.data;
      // this.clients = data.data
      // this.isLoading = false
      console.log(data);
      if (data.data.length >= 1) {
        this.packagesfetchState.has_data = true;
      } else {
        this.packagesfetchState.no_data = true;
      }
        this.packagesfetchState.started = false;
    },
    // createPackage() {
    //   this.$router.push({
    //     path: "/create-package",
    //   });
    // },
  },
};
</script>
<style scoped>
.empty-package--content-area {
  border: dashed 2px #cfcfcf;
  margin: 0 auto;
}
</style>